import { useEffect } from "react"
import AemGridColumn from "@/components/AemGridColumn"
import BrandHeader from "@/components/Shared/BrandHeader"
import HeaderNav from "@/components/Shared/HeaderNav"
import Video from "@/components/Default/Video/v1/Video"
import Carousel from "@/components/Default/Carousel/v1/Carousel"
import CarouselSlick from "@/components/Default/CarouselSlick/v1/CarouselSlick"
import Footer from "@/components/Shared/Footer"
import CustomImage from "@/components/Default/Image/v3/Image"
import Image from "@/components/Default/Image/v1/Image"
import List from "@/components/Default/List/v1/List"
import HelpSupport from "@/components/HelpSupport/v1/HelpSupport"
import FindAStore from "@/components/FindAStore/FindAStore"
import {
  isAemForm,
  isBrandHeader,
  isCarousel,
  isFooter,
  isHeaderNav,
  isSlick,
  isTab,
  isTeaser,
  isText,
  isVideo,
  isImage,
  isAemImage,
  isAemList,
  isCartLanding,
  isPairsWellWth,
  isCheckoutLanding,
  isEmbed,
  isSharedCart,
  isOrderConfirmation,
  isOrderDetails,
  isOrderHistory,
  isOrderStatus,
  isAuthModal,
  isNewsLetter,
  isSpacer,
  ConditionalWrapper,
  isBreadcrumb,
  isProductList,
  isProductDetails,
  isSearch,
  isSiteWideWarning,
  isPromoBanner,
  isToaster,
  isCompareProducts,
  isTeaserContainer,
  isNewsLetterSignUp,
  isHelpSupport,
  isAccountSettings,
  isMyservice,
  isProductAccordion,
  isProductReview,
  isRegisterOffline,
  isRegisterOnline,
  isMyFavorites,
  isMyproducts,
  isProductcard,
  isProductExfPdp,
  isKnowledgeArticle,
  isBrowseAllStores,
  isLiterature,
  isFindingModelNumber,
  isPressLanding,
  isCustomerSupport,
  isFindAStore,
  isProductEnquiry,
  isDealerEnquiry,
  isReturns,
  isAssociateMembership,
  isBlogPage,
} from "@/utils/index"
import Teaser from "@/components/Default/Teaser/v1/Teaser"
import Text from "@/components/Default/Text/v1/Text"
import Tab from "@/components/Default/Tab/v1/Tab"

import { setAuthModalStaticText } from "@/store/features/authSlice"
import {
  setSubscribeModalContent,
  setSitewideWarningContent,
  setCompareproductsStaticText,
  setSitewideNewsLetterContent,
  setToastContent,
} from "@/store/features/genericSlice"
import { useDispatch } from "react-redux"

import Embed from "@/components/Shared/Embed"
import Spacer from "@/components/Default/Spacer/v1/Spacer"
import PromoBanner from "@/components/Default/PromoBanner/v1/PromoBanner"

import useIsSsr from "@/hooks/useIsSsr"
import AemForm from "@/components/Default/AemForm"
import ProductCard from "@/components/productCard/v1/ProductCard"
import FindingModelNumber from "@/components/FindingModelNumber/v1/FindingModelNumber"
import ProductEnquiryView from "./ProductEnquiry/ProductEnquiryView"
import BlogLanding from "@/components/Blog/index"

const AemGrid = props => {
  const {
    data = {},
    itemKey = "",
    count = 0,
    eventHandler = {},
    fullData = {},
    containerId = "",
    ...extraProps
  } = props
  const dispatch = useDispatch()
  const isSsr = useIsSsr()

  useEffect(() => {
    if (isAuthModal(data)) {
      dispatch(setAuthModalStaticText(data))
    }

    if (isCompareProducts(data)) {
      dispatch(setCompareproductsStaticText(data))
    }

    if (isNewsLetter(data)) {
      dispatch(setSubscribeModalContent(data))
    }

    if (isSiteWideWarning(data)) {
      dispatch(setSitewideWarningContent(data))
    }
    if (isNewsLetterSignUp(data)) {
      dispatch(setSitewideNewsLetterContent(data))
    }
    if (isToaster(data)) {
      dispatch(setToastContent(data))
    }
  }, [data])
  const { pressReleaseLanding = () => {}, associateMembership = () => {} } =
    extraProps
  if (isVideo(data)) {
    return <Video data={data} />
  }

  if (isSpacer(data)) {
    return <Spacer data={data} />
  }

  if (isImage(data)) {
    return <CustomImage data={data} />
  }

  if (isAemForm(data)) {
    return <AemForm data={data} fullData={fullData} />
  }
  if (isProductcard(data)) {
    return <ProductCard data={data} fullData={fullData} />
  }

  if (isCarousel(data)) {
    return <Carousel data={data} fullData={fullData} />
  }

  if (isPromoBanner(data)) {
    return <PromoBanner data={data} />
  }

  if (isSlick(data)) {
    return <CarouselSlick fullData={fullData} data={data} />
  }

  if (isTeaser(data)) {
    return <Teaser data={data} fullData={fullData} />
  }

  if (isText(data)) {
    return <Text data={data} />
  }

  if (isTab(data) && data["appliedCssClassNames"]) {
    return <Tab data={data} />
  }

  if (isBrandHeader(data)) {
    return <BrandHeader data={data} eventHandler={eventHandler} />
  }

  if (isHeaderNav(data)) {
    return <HeaderNav data={data} eventHandler={eventHandler} />
  }

  if (isEmbed(data)) {
    return <Embed data={data} eventHandler={eventHandler} />
  }

  if (isFooter(data)) {
    return <Footer data={data} eventHandler={eventHandler} />
  }

  if (isCustomerSupport(data)) {
    return extraProps && extraProps.landing && extraProps.landing(data)
  }

  if (isAemImage(data)) {
    return !isSsr ? <Image data={data} /> : null
  }

  if (isAemList(data)) {
    return <List data={data} />
  }

  if (isCartLanding(data)) {
    if (!isSsr) {
      return extraProps && extraProps.landing && extraProps.landing(data)
    } else {
      return (
        extraProps && extraProps.landingFiller && extraProps.landingFiller(data)
      )
    }
  }

  if (isCheckoutLanding(data) && !isSsr) {
    if (extraProps.paymentResp) {
      data.paymentResp = extraProps.paymentResp
    }
    return (
      extraProps && extraProps.checkoutView && extraProps.checkoutView(data)
    )
  }

  if (isOrderConfirmation(data)) {
    if (!isSsr) {
      return (
        extraProps &&
        extraProps.orderConfirmation &&
        extraProps.orderConfirmation(data)
      )
    } else {
      return (
        extraProps &&
        extraProps.orderConfirmationTagFiller &&
        extraProps.orderConfirmationTagFiller()
      )
    }
  }

  if (isOrderStatus(data)) {
    if (!isSsr) {
      return (
        extraProps && extraProps.orderStatus && extraProps.orderStatus(data)
      )
    } else {
      return (
        extraProps &&
        extraProps.orderStatusFiller &&
        extraProps.orderStatusFiller()
      )
    }
  }

  if (isOrderHistory(data) && !isSsr) {
    return (
      extraProps && extraProps.orderHistory && extraProps.orderHistory(data)
    )
  }

  if (isOrderDetails(data)) {
    if (!isSsr) {
      return (
        extraProps && extraProps.orderDetails && extraProps.orderDetails(data)
      )
    } else {
      return (
        extraProps &&
        extraProps.orderDetailsFiller &&
        extraProps.orderDetailsFiller(data)
      )
    }
  }

  if (isPairsWellWth(data) && !isSsr) {
    return (
      extraProps && extraProps.pairsWellWith && extraProps.pairsWellWith(data)
    )
  }

  if (isSharedCart(data) && !isSsr) {
    return extraProps && extraProps.sharedCart && extraProps.sharedCart(data)
  }

  if (isBreadcrumb(data)) {
    return (
      extraProps &&
      extraProps.breadCrumb &&
      extraProps.breadCrumb(data, fullData)
    )
  }

  if (isProductEnquiry(data)) {
    // return extraProps && extraProps.landing && extraProps.landing(data)
    return <ProductEnquiryView data={data} />
  }

  if (isDealerEnquiry(data)) {
    return extraProps && extraProps.landing && extraProps.landing(data)
  }

  if (isBlogPage(data)) {
    return <BlogLanding data={data} />
  }

  if (isReturns(data)) {
    return extraProps && extraProps.landing && extraProps.landing(data)
  }

  if (isFindAStore(data)) {
    return (
      <>
        <ConditionalWrapper
          condition={
            data?.[":itemsOrder"] &&
            data?.[":itemsOrder"]?.length === 1 &&
            data?.[":itemsOrder"]?.includes("text") &&
            !isTeaserContainer(data)
          }
          wrapper={children => (
            <div className={data?.gridClassNames}>{children}</div>
          )}
        >
          <AemGridColumn
            data={data}
            itemKey={itemKey}
            count={count}
            eventHandler={eventHandler}
            containerId={containerId}
            {...extraProps}
          />
        </ConditionalWrapper>
        <FindAStore data={data} stateWithCities={extraProps.stateWithCities} />
      </>
    )
  }

  if (isProductList(data)) {
    return (
      extraProps &&
      extraProps.productList &&
      extraProps.productList(data, eventHandler)
    )
  }
  if (isSearch(data)) {
    if (!isSsr) {
      return (
        extraProps &&
        extraProps.searchComponent &&
        extraProps.searchComponent(data, eventHandler)
      )
    } else {
      return (
        extraProps && extraProps.searchFiller && extraProps.searchFiller(data)
      )
    }
  }

  if (isHelpSupport(data) && !isSsr) {
    return <HelpSupport data={data} />
  }

  if (isAccountSettings(data) && !isSsr) {
    return (
      extraProps &&
      extraProps.accountSettings &&
      extraProps.accountSettings(data)
    )
  }
  if (isMyservice(data) && !isSsr) {
    return extraProps && extraProps.myServices && extraProps.myServices(data)
  }
  if (isMyFavorites(data) && !isSsr) {
    return extraProps && extraProps.myFavorites && extraProps.myFavorites(data)
  }

  if (isMyproducts(data)) {
    if (!isSsr) {
      return extraProps && extraProps.myProducts && extraProps.myProducts(data)
    } else {
      return (
        extraProps &&
        extraProps.myProductsFiller &&
        extraProps.myProductsFiller()
      )
    }
  }
  if (isLiterature(data) && !isSsr) {
    return extraProps && extraProps.literature && extraProps.literature(data)
  }

  if (isProductDetails(data)) {
    if (!isSsr) {
      return (
        extraProps &&
        extraProps.productDetail &&
        extraProps.productDetail(
          data,
          extraProps?.slug,
          extraProps?.skuId,
          extraProps?.type
        )
      )
    } else {
      return (
        extraProps &&
        extraProps.productDetailFiller &&
        extraProps.productDetailFiller(data)
      )
    }
  }
  if (isProductAccordion(data) && !isSsr) {
    return (
      extraProps &&
      extraProps.productAccordion &&
      extraProps.productAccordion(data)
    )
  }

  if (isProductExfPdp(data) && !isSsr) {
    return extraProps && extraProps.productExf && extraProps.productExf(data)
  }

  if (isProductReview(data) && !isSsr) {
    return extraProps && extraProps.review && extraProps.review(data)
  }

  if (isRegisterOffline(data)) {
    if (!isSsr) {
      return (
        extraProps &&
        extraProps.offlineProducts &&
        extraProps.offlineProducts(data)
      )
    } else {
      return (
        extraProps &&
        extraProps.offlineProducts &&
        extraProps.offlineProductsFiller()
      )
    }
  }

  if (isRegisterOnline(data)) {
    if (!isSsr) {
      return (
        extraProps &&
        extraProps.onlineProducts &&
        extraProps.onlineProducts(data)
      )
    } else {
      return (
        extraProps &&
        extraProps.onlineProducts &&
        extraProps.offlineProductsFiller()
      )
    }
  }

  if (isKnowledgeArticle(data)) {
    if (!isSsr) {
      return (
        extraProps &&
        extraProps.knowledgeArticle &&
        extraProps.knowledgeArticle(data)
      )
    } else {
      return (
        extraProps &&
        extraProps.knowledgeArticleFiller &&
        extraProps.knowledgeArticleFiller()
      )
    }
  }

  if (isBrowseAllStores(data) && !isSsr) {
    return (
      extraProps &&
      extraProps.browseAllStores &&
      extraProps.browseAllStores(data)
    )
  }

  if (isFindingModelNumber(data) && !isSsr) {
    return <FindingModelNumber data={data} />
  }

  if (isPressLanding(data) && !isSsr) {
    return pressReleaseLanding(data)
  }
  if (isAssociateMembership(data)) {
    return associateMembership(data)
  }

  return (
    <>
      <ConditionalWrapper
        condition={
          data?.[":itemsOrder"] &&
          data?.[":itemsOrder"]?.length === 1 &&
          data?.[":itemsOrder"]?.includes("text") &&
          !isTeaserContainer(data)
        }
        wrapper={children => (
          <div className={data?.gridClassNames}>{children}</div>
        )}
      >
        <AemGridColumn
          data={data}
          itemKey={itemKey}
          count={count}
          eventHandler={eventHandler}
          containerId={containerId}
          {...extraProps}
        />
      </ConditionalWrapper>
    </>
  )
}

export default AemGrid
