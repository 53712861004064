import { useEffect, useState } from "react"
import _get from "lodash/get"
import { useRouter } from "next/router"
import { useDispatch, useSelector } from "react-redux"
import { isMobile, isTablet } from "react-device-detect"
import Button from "@/components/core/Button/Button"
import Input from "@/components/core/Input/Input"
import { validNumber, validText, validateEmail } from "@/utils/validate"
import { validateField } from "@/utils/helper"
import { PHONE_REGEX } from "@/constants/index"
import { submitProductEnquiry } from "@/utils/enquiry"
import SelectDropdown from "@/components/core/SelectDropdown/SelectDropdown"
import TextArea from "@/components/core/TextArea/TextArea"
import { submitEnquiryAnalytics } from "@/components/ProductEnquiry/analytics"

import {
  getplanTimingList,
  getplanTypeList,
  getGenericListPath,
} from "@/store/features/genericSlice"

const Enquiry = props => {
  const { staticText, setConfirmationSuccess } = props

  const dispatch = useDispatch()
  const [error, setError] = useState({})
  const router = useRouter()
  const [data, setData] = useState({})
  const [comments, setComments] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatch(getGenericListPath()).then(() => {
      dispatch(getplanTimingList())
      dispatch(getplanTypeList())
    })
  }, [])

  const generic = useSelector(state => state.generic)

  const onNameChange = e => {
    const { name, value } = e.target
    if (value === "" || validText(value)) {
      setData({
        ...data,
        [name]: value,
      })
    }
  }
  const onChangePincode = e => {
    const { value } = e.target
    if (value === "" || validNumber(value)) {
      setData({
        ...data,
        pincode: value,
      })
    }
    if (!value?.trim())
      setError({
        ...error,
        pincode: staticText?.errorPincode,
      })
    else if (value?.length !== 6)
      setError({
        ...error,
        pincode: staticText?.pincodeValid,
      })
  }
  const onBlur = field => {
    setError({
      ...error,
      [field]: validateExist(field),
    })
  }

  const validateExist = field => {
    if (field === "firstName" || field === "lastName") {
      if (!data[field]?.trim() || !validateField("name", data[field])) {
        return field === "firstName"
          ? staticText?.firstNameError
          : staticText?.lastNameError
      } else return ""
    } else if (field === "pincode") {
      if (!data?.pincode?.trim()) return staticText?.errorPincode
      else if (data?.pincode?.length !== 6) return staticText?.pincodeValid
    } else if (field === "phone") {
      if (!data?.phone?.trim() || !PHONE_REGEX.test(data?.phone)) {
        return staticText?.errorMobileNumber
      }
    } else if (field === "emailId") {
      if (!data[field]) return staticText?.provideEmailError
      else if (
        !validateField("emailId", data[field]) ||
        !validateEmail(data[field])
      )
        return staticText?.validEmailId
      else {
        return ""
      }
    } else if (field === "planning" || field === "planStartsFrom") {
      if (!data[field]) {
        return staticText?.errorSelectBox
      }
    }
  }
  const handleSubmit = async () => {
    const errorList = getErrorList()
    setIsLoading(true)
    const { id, ...rest } = router.query
    let errorDataList = []
    setError(errorList)
    if (errorList?.firstName) {
      errorDataList.push(`firstName`)
    } else if (errorList?.lastName) {
      errorDataList.push(`lastName`)
    } else if (errorList?.pincode) {
      errorDataList.push(`pincode`)
    } else if (errorList?.planning) {
      errorDataList.push(`planning`)
    } else if (errorList?.planStartsFrom) {
      errorDataList.push(`planStartsFrom`)
    }
    errorDataList.map((item, i) => {
      if (i === 0) document.getElementById(`${item}`)?.focus()
    })
    const isInvalid = Object?.values(errorList)?.find(el => el)
    if (!isInvalid) {
      const {
        firstName,
        lastName,
        pincode,
        phone,
        emailId,
        planStartsFrom,
        planning,
        comments,
      } = data
      const payload = {
        firstName: firstName?.trim(),
        pincode: pincode,
        mobile: phone,
        lastName: lastName?.trim(),
        enquiryType: "design-enquiry",
        email: emailId ?? "",
        plan: planning,
        planStartsFrom: planStartsFrom,
        comments: comments,
      }
      const utmParams = [
        "utm_source",
        "utm_content",
        "utm_medium",
        "utm_term",
        "utm_campaign",
      ]
      utmParams.forEach(param => {
        if (rest?.[param]) {
          payload[param] = decodeURIComponent(rest[param])
        }
      })
      const response = await submitProductEnquiry(payload)
      if (response.statusCode === 200) {
        submitEnquiryAnalytics("success")
        setConfirmationSuccess(true)
      } else {
        submitEnquiryAnalytics("failure", "form submission failed")
        setConfirmationSuccess(false)
      }
    } else {
      submitEnquiryAnalytics("failure", "form validation failed")
    }
    setIsLoading(false)
  }

  const getErrorList = () => {
    const errorList = {
      firstName: validateExist("firstName"),
      lastName: validateExist("lastName"),
      pincode: validateExist("pincode"),
      phoneNumber: validateExist("phone"),
      emailId: validateExist("emailId"),
      planning: validateExist("planning"),
      planStartsFrom: validateExist("planStartsFrom"),
    }
    return errorList
  }

  const isEnabled = Object?.values(getErrorList())?.find(el => el)
    ? true
    : false

  const handleChangeNumber = event => {
    const { value } = event.target
    if (value === "" || validNumber(value)) {
      setData({
        ...data,
        phone: value,
      })
      setError({
        ...error,
        phone: "",
      })
    }
  }
  const onChangeEmailId = e => {
    const { value } = e.target
    setData({
      ...data,
      emailId: value,
    })

    if (value) {
      if (validateEmail(value)) {
        setError({
          ...error,
          emailId: null,
        })
      } else {
        setError({
          ...error,
          emailId: staticText.validEmailId,
        })
      }
    }
  }
  const handleSelectChange = (value, type) => {
    setData({ ...data, [type]: value })
    setError({ ...error, [type]: false })
  }
  const handleSelectBlur = type => {
    setError({ ...error, [type]: !data[type] })
  }
  const handleChangeComments = async value => {
    setComments(value)
    setData({
      ...data,
      comments: value,
    })
  }

  return (
    <div className="product-enquiry__margin-div">
      <div className="product-enquiry__phoneDiv">
        <div className="product-enquiry__countryIcon">
          <Input
            customClass="product-enquiry__countryField"
            id="contact-user-country-code"
            type="text"
            disabled
            value={"+91"}
          />
        </div>
        <div className="product-enquiry__phoneNumber">
          <Input
            customClass="product-enquiry__inputfield"
            id="contact-user-phone"
            type="text"
            value={data.phone ?? ""}
            maxLength={10}
            placeholder={staticText?.mobileNumber}
            label={staticText?.mobileNumber}
            onChange={handleChangeNumber}
            showError={error?.phone}
            errorMessage={error?.phone}
            onBlur={() => onBlur("phone")}
          />
        </div>
      </div>

      <div className="row mx-0">
        <div
          className={`col-12 col-sm-6 col-md-6 pl-0 ${
            isMobile || isTablet ? "pr-20" : "pr-0"
          }`}
        >
          <Input
            id="contact-user-fname"
            type="text"
            customClass="customer-support-inputfield"
            value={data?.firstName ?? ""}
            maxLength={40}
            placeholder={staticText?.firstName}
            label={staticText?.firstName}
            onChange={onNameChange}
            showError={error?.firstName}
            errorMessage={error?.firstName}
            onBlur={() => onBlur("firstName")}
            name="firstName"
            autoComplete={false}
          />
        </div>
        <div
          className={`col-12 col-sm-6 col-md-6 pr-0 ${
            isMobile || isTablet ? "pl-0" : "pl-20"
          }`}
        >
          <Input
            id="contact-user-lname"
            type="text"
            customClass="customer-support-inputfield"
            value={data?.lastName ?? ""}
            maxLength={40}
            placeholder={staticText?.lastName}
            label={staticText?.lastName}
            onChange={onNameChange}
            showError={error?.lastName}
            errorMessage={error?.lastName}
            onBlur={() => onBlur("lastName")}
            name="lastName"
            autoComplete={false}
          />
        </div>
      </div>
      <Input
        id="contact-user-emailId"
        type="text"
        placeholder={staticText?.email}
        label={staticText?.email}
        value={data?.emailId ?? ""}
        onChange={onChangeEmailId}
        onInput={onChangeEmailId}
        showError={error?.emailId !== "" && error?.emailId}
        errorMessage={error?.emailId}
        onBlur={() => onBlur("emailId")}
        autoComplete={false}
      />

      <Input
        id="contact-user-pincode"
        type="text"
        placeholder={staticText?.pinCode}
        label={staticText?.pinCode}
        value={data?.pincode ?? ""}
        maxLength={6}
        autoComplete={false}
        onChange={onChangePincode}
        showError={error?.pincode}
        errorMessage={error?.pincode}
        onBlur={() => onBlur("pincode")}
      />

      <div className="row mx-0">
        <div
          className={`col-12 col-sm-6 col-md-6 pl-0 pb-20 ${
            isMobile || isTablet ? "pr-20" : "pr-0"
          }`}
        >
          <SelectDropdown
            label={staticText.planToStartLabel}
            data={generic["planTimingList"]?.map(value => value["value"])}
            value={data?.planStartsFrom}
            onChange={val => handleSelectChange(val, "planStartsFrom")}
            onBlur={() => handleSelectBlur("planStartsFrom")}
            showError={error?.planStartsFrom}
            errorMessage={staticText.errorSelectBox}
            id="planStartsFrom"
          />
        </div>
        <div
          className={`col-12 col-sm-6 col-md-6 pr-0 pb-20 ${
            isMobile || isTablet ? "pl-0" : "pl-20"
          }`}
        >
          <SelectDropdown
            label={staticText.planningLabel}
            data={generic["planTypeList"]?.map(value => value["value"])}
            value={data.planning}
            onChange={val => handleSelectChange(val, "planning")}
            onBlur={() => handleSelectBlur("planning")}
            showError={error?.planning}
            errorMessage={staticText.errorSelectBox}
            id="planning"
          />
        </div>
      </div>
      <div className="enquiry__formtextarea">
        <TextArea
          label={staticText.commentsLabel}
          placeholder={staticText.commentsLabel}
          onChange={e => handleChangeComments(e.target.value)}
          value={comments}
          className="enquiry__textarea"
          maxLength="250"
        />
      </div>

      <div className="product-enquiry__privacyText">
        {staticText?.privacyText}
      </div>
      <div className="product-enquiry__buttonDiv">
        <Button
          type="black"
          role="button"
          label={staticText.submit}
          className="product-enquiry__button"
          flexible
          onClick={handleSubmit}
          disabled={isEnabled}
          loading={isLoading}
        />
      </div>
    </div>
  )
}

export default Enquiry
